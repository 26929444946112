'use client'

import * as React from 'react'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import ClearIcon from '@mui/icons-material/Clear'
import SendIcon from '@mui/icons-material/Send'
import ResetIcon from '@mui/icons-material/RestartAlt'
import Message from '@/components/message'
import classes from './nadlii.module.css'
import { useAppStore } from '@/store/appstore'
import { SimpleId } from '@/lib/utils'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/system';

import Typography from '@mui/material/Typography'

const customTheme = createTheme({
    typography: {
        // Tell MUI what the font-size on the html element is.
        //htmlFontSize: 12,
        
    },
    palette: {
        primary: {
            main: '#0097a9',
        },
        secondary: {
            main: '#f2a900',
        },
    },
});
  
const PrePopSearchButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 50,
    fontSize: '1rem',
    fontWeight: 'normal',
});

const IamRadio = styled(FormControlLabel)({
    border: '1px solid #ddd',
    borderRadius: 50,
    backgroundColor: '#fff',
    paddingRight: 16,
    margin: 0,
});
  
export default function UFA() {

    const threadId = useAppStore((state) => state.threadId)
    const setThreadId = useAppStore((state) => state.setThreadId)

    const timRef = React.useRef(null)
    const messageRef = React.useRef(null)
    //const inputRef = React.useRef(null)

    const [messageItems, setMessageItems] = React.useState([])
    const [inputText, setInputText] = React.useState('')
    const [isLoading, setLoading] = React.useState(false)
    const [isMounted, setMounted] = React.useState(false)
    const [isWaiting, setWaiting] = React.useState(false)
    const [isComposing, setComposing] = React.useState(false)
    
    const [iAmValue, setIAmValue] = useState('citizen')
    const [prePopSubmit, setPrePopSubmit] = useState(false)


    React.useEffect(() => {
        setMounted(true)
    }, [])

    React.useEffect(() => {
        if (isMounted) {
            if (threadId) {
                /**
                 * Delete previous thread when app starts
                 */
                deleteThread(threadId)
                setThreadId('')
            }
        }

    }, [isMounted])

    const deleteThread = async (thread_id) => {

        try {
            const response = await fetch('/api/thread', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ thread_id })
            })

            const result = await response.json()

            console.log(result)

        } catch (e) {
            console.log(e.message)
        }

    }

    const handleButtonSubmit = (e) => {
        const text = e.target.innerText
        setInputText(text)
        setPrePopSubmit(true)
    };

    React.useEffect(() => {
        if (prePopSubmit) {
          handleSubmit()
          setPrePopSubmit(false)
        }
      }, [prePopSubmit])    


    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoading(true)

        const text = inputText
        const iAmValueText = iAmValue

        console.log(text)
        console.log(iAmValueText)

        const message = {
            id: SimpleId(),
            role: 'user',
            createdAt: Date.now(),
            content: text,
        }

        setMessageItems((prev) => [...prev, ...[message]])
        setInputText('')
        resetScroll()

        try {

            const response = await fetch('/api/stream', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    message: text,
                    thread_id: threadId,
                    iAmValue: 'The user is a ' + iAmValueText + '. Use lanaguge and respond in a manner that is relevant to them.',
                })
            })

            const reader = response.body.getReader()
            const assistantId = SimpleId()
            const assistant_message = {
                id: assistantId,
                role: 'assistant',
                createdAt: Date.now(),
                content: '',
            }

            setMessageItems((prev) => [...prev, ...[assistant_message]])
            setWaiting(true)

            resetScroll()

            let is_completed = false
            let thread_id = threadId

            while (!is_completed) {

                const { done, value } = await reader.read()

                if (done) {
                    is_completed = true
                    break
                }
                const raw_delta = new TextDecoder().decode(value)
                let delta

                try {
                    delta = JSON.parse(raw_delta)
                    if (typeof delta !== 'object' || delta === null) {
                        delta = null
                        throw new Error('Parsed JSON is not an object')
                    }
                } catch (e) {
                    console.log(e.message)
                }
                if (delta) {
                    if (delta.thread_id) {
                        thread_id = delta.thread_id
                    } else if (delta.wait) {
                        setWaiting(true)
                        resetScroll()
                    } else if (delta.longwait) {
                        setMessageItems((prev) => {
                            return prev.map((a) => {
                                return {
                                    ...a,
                                    content: a.id !== assistantId ? a.content : a.content + '\n\n'
                                }
                            })
                        })
                        setWaiting(true)
                        resetScroll()
                    }
                } else {
                    setWaiting(false)
                    setMessageItems((prev) => {
                        return prev.map((a) => {
                            return {
                                ...a,
                                content: a.id !== assistantId ? a.content : a.content + raw_delta
                            }
                        })
                    })
                }
            }
            setWaiting(false)
            setThreadId(thread_id)
            resetScroll()
        } catch (e) {
            console.log(e.message)
        } finally {
            setLoading(false)
        }

    }

    const handleIamChange = (event) => {
        setIAmValue(event.target.value);
        console.log(event.target.value);
    };

    const resetScroll = () => {
        clearTimeout(resetScroll)
        timRef.current = setTimeout(() => {
            messageRef.current.scrollTop = messageRef.current.scrollHeight
        }, 100)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            if (!isComposing) {
                handleSubmit(e)
            }
        }
    }

    const handleStartComposition = () => {
        setComposing(true)
    }

    const handleEndComposition = () => {
        setComposing(false)
    }

    const handleReset = () => {

        deleteThread(threadId)

        setThreadId('')
        setMessageItems([])

    }

    return (
        <ThemeProvider theme={customTheme}>
            <div className={classes.container}>
                <div className={classes.nadlii_section_large_heading}>
                    <div className={classes.f_container_regular_heading}>
                        <div className={classes.wf_layout_layout}>
                            <div className={classes.w_layout_cell}>
                                <img
                                    className={classes.thumb_img}
                                    src="images/umbrella-agreement-cover2.png"
                                    loading="lazy"
                                    sizes="(max-width: 479px) 94vw, (max-width: 991px) 90vw, 43vw"
                                    srcSet="images/umbrella-agreement-cover2-p-500.png 500w, images/umbrella-agreement-cover2.png 704w"
                                    alt="" />
                            </div>
                            <div className={classes.w_layout_cell}>
                                <div className={classes.f_margin_bottom_24}>
                                    <h1 className={classes.nadlii_h1_heading}>
                                        Umbrella Final Agreement
                                    </h1>
                                </div>
                                <div className={classes.f_margin_bottom_24}>
                                <p className={classes.nadlii_paragraph_large_subhead}>
                                    between{" "}
                                    <span className="nadlii-span-regbody">
                                        THE GOVERNMENT OF CANADA, THE COUNCIL FOR YUKON
                                        FIRST NATIONS
                                    </span>{" "}
                                    and{" "}
                                    <span className="nadlii-span-regbody">
                                        THE GOVERNMENT OF THE YUKON
                                    </span>
                                </p>
                                </div>
                                <div className={classes.f_margin_bottom_24}>
                                    <a href="docs/eco-ar-umbrella-final-agreement.pdf" download alt="Download the Umbrella Final Agreement">
                                        <Button className={classes.downloadButton} variant="contained" disableElevation>
                                            Read the Document
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.f_container_regular}>
                    <div className={classes.w_layout_cell}>
                        <div className={classes.nadlii_searchcircle}>
                            <img
                                src="images/indeterminate_question_box.svg"
                                loading="lazy"
                                alt=""
                                className={classes.nadlii_search_icon} />
                        </div>
                        <div className={classes.nadlii_feature_card_outlined_top}>
                            <div className={classes.nadlii_card_margin_bottom_padleft}>
                                <div className={classes.nadlii_form_extwrap}>
                                    <div className="nadlii-search-form-wrapper-sml">
                                        <div className="f-header-form-wrapper">
                                            <div className={classes.input}>
                                                <Box component="form" onSubmit={handleSubmit} noValidate>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <RadioGroup className={classes.iAmRadioButtons} row aria-labelledby="iAmValueRadio" name="iAmValueRadio" value={iAmValue} onChange={handleIamChange}>
                                                                <IamRadio className={classes.radioButton} value="citizen" defaultChecked control={<Radio />} label="I am a Citizen" />
                                                                <IamRadio className={classes.radioButton} value="student" control={<Radio />} label="I am a Student" />
                                                                <IamRadio className={classes.radioButton} value="academic" control={<Radio />} label="I am an Academic" />
                                                                <IamRadio className={classes.radioButton} value="lawyer" control={<Radio />} label="I am a Lawyer" />
                                                                <IamRadio className={classes.radioButton} value="researcher" control={<Radio />} label="I am a Researcher" />
                                                            </RadioGroup>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={handleReset}>
                                                                <ResetIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <TextField
                                                        className={classes.inputText}
                                                        sx={{
                                                            bgcolor: '#fff',
                                                            marginTop: 2,
                                                            borderRadius: 15,
                                                        }}
                                                        disabled={isLoading}
                                                        fullWidth
                                                        multiline
                                                        maxRows={3}
                                                        inputRef={(input) => input && input.focus()}
                                                        //inputRef={inputRef}
                                                        value={inputText}
                                                        placeholder="Ask anything about the Umbrella Final Agreement..."
                                                        onChange={(e) => setInputText(e.target.value)}
                                                        onKeyDown={handleKeyDown}
                                                        onCompositionStart={handleStartComposition}
                                                        onCompositionEnd={handleEndComposition}
                                                        autoFocus
                                                        //focused
                                                        InputProps={{
                                                            sx: { borderRadius: "50px" },
                                                            classes: { input: classes.inputFieldText },
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setInputText("")}
                                                                        disabled={!inputText || isLoading}
                                                                    >
                                                                        <ClearIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={(e) => handleSubmit(e)}
                                                                        disabled={!inputText || isLoading}
                                                                    >
                                                                        <SendIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                    <Box><Grid className={classes.prePopSearch} gap={1.25} container alignItems="center">
                                                        <PrePopSearchButton variant="contained" color="secondary" disableElevation onClick={handleButtonSubmit}>How does the Umbrella Agreement impact me?</PrePopSearchButton>
                                                        <PrePopSearchButton variant="contained" color="secondary" disableElevation onClick={handleButtonSubmit}>What is the Umbrella Agreement?</PrePopSearchButton>
                                                        <PrePopSearchButton variant="contained" color="secondary" disableElevation onClick={handleButtonSubmit}>What is the spirit and intent of the UFA?</PrePopSearchButton>
                                                        <PrePopSearchButton variant="contained" color="secondary" disableElevation onClick={handleButtonSubmit}>What is a land reserve and land set aside?</PrePopSearchButton>
                                                        <PrePopSearchButton variant="contained" color="secondary" disableElevation onClick={handleButtonSubmit}>What is the difference between The Council for Yukon Indians and Council of Yukon First Nations?</PrePopSearchButton>
                                                    </Grid>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.messages} ref={messageRef}>
                    {messageItems.map((msg, index) => {
                        return (
                            <Message
                                key={msg.id}
                                message={msg}
                                isWaiting={index === messageItems.length - 1 && isWaiting} />
                        )
                    })}
                </div></div>
        </ThemeProvider>
    );
}